<template>
  <div class="sheet">
    <form @submit="$root.postData">
      <div class="framing-x bg-content">
        <article class="article">
          <h2 class="h2">{{$root.$data.BasicInfo1.name}}，恭喜你！！即將進入一場神奇又準確的職業適性分析，請用最直覺的方式作答，有些作答會讓你左右為難，難以決定時請想想別人眼中的你是甚麼樣子。</h2>

          <div class="infoBox text-primary">
            <div class="infoBox-title mb-2 mb-lg-3 pb-lg-1">人格特質分析</div>
            <div class="text-small">
              (例A) 你是個幽默還是嚴肅的人呢？如你覺得你是一個很幽默的人，請勾：幽默(強)；如你覺得你是一個有點嚴肅的人，
              請勾：嚴肅弱；如你覺得你是一板一眼的人，請勾：嚴肅強
            </div>
          </div>

          <fieldset :id="'q1-' + questionIndex" v-for="(question, questionIndex) in questions" :key="questionIndex">
            <label>{{ question.title }}</label>
            <div class="row">
              <div class="col-12 col-lg-3" v-for="(option, optionIndex) in question.options" :key="optionIndex">
                <label class="form-radio text-center mb-lg-0">
                  <input type="radio" :name="'sheet1['+ questionIndex +']'" :value="optionIndex+1" v-model="$root.$data.Sheet1[questionIndex]" @click="$root.scrollToElement(questionEl(questionIndex+1))" required />
                  <span class="form-radio-value">{{ option }}</span>
                </label>
              </div>
            </div>
          </fieldset>
        </article>
      </div>
      <div class="framing-x framing-y">
        <button class="btn btn-primary w-100 footer-button" @click="next">下一頁</button>
      </div>
    </form>
  </div>
</template>

<script>
import questions from '../data/questions.js'

export default {
  data() {
    return {
      questions: questions('sheet1')
    }
  },
  methods: {
    questionEl(questionIndex) {
      return document.getElementById("q1-" + questionIndex);
    },
    next(e) {
      if (!this.$root.reportFormValidity(document.querySelector('form'))) {
        e.preventDefault();
        return false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.infoBox {
  margin-top: 1.5rem;
}
@media screen and (min-width: 960px) {
  .infoBox {
    margin-top: 2.25rem;
  }
}
</style>
